.ng-select.ng-select-single .ng-select-container {
  height: 35px;
}
.ng-select .ng-select-container {
  color: #495057;
  border-radius: 8px;
  border: 1px solid #ced4da;
  min-height: 35px;
  align-items: center;
  font-size: 14px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-left: 0.75rem;
}
.ng-select .ng-clear-wrapper {
  line-height: 14px;
  font-size: 8px;
}
.ng-select .ng-arrow-wrapper {
  width: 30px;
  padding-right: 0;
  height: 33px;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  border: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  top: 0;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 14px;
  padding: 7px 10px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  z-index: 0 !important;
}
.ng-select .ng-arrow-wrapper .ng-arrow,
select {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 20px;
  background-size: 1em;
}
select {
  appearance: none;
}

.form-control, .badge, .btn {
  border-radius: 8px;
}
.form-control:focus {
  box-shadow: none;
}
button:focus {
  outline: none !important;
}
.btn, .form-control, .input-group-text {
  font-size: var(--layout-text-font-size) !important;
  line-height: 20px !important;
}
::placeholder {
  color: #57636C;
}
.text-lines-truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  &.lines-5 {
    -webkit-line-clamp: 5;
    line-clamp: 5;
  }
  &.lines-1 {
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
  &.lines-2 {
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }
  &.lines-3 {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
}
