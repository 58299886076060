// @nebular theming framework
@import '@nebular/theme/styles/theming';
// @nebular out of the box themes
@import '@nebular/theme/styles/themes';

$primary: #0053A1;
$gray-hard: #374955;
$gray-soft: #57636C;
$gray-light: #BDC3C7;
$theme-colors: (
  primary: $primary,
  // secondary: $secondary,
  // my-color: $my-color
);

$nb-themes: nb-register-theme((
  layout-padding-top: 2.25rem,
  layout-background-color: #fff,
  header-background-color: #343a40,
  sidebar-background-color: #343a40,
  header-shadow: none,
  sidebar-shadow: none,
  menu-item-active-text-color: #2D8C6D,
  menu-item-hover-text-color: #2D8C6D,
  menu-item-active-background-color: #f4f6f9,
  menu-text-color: #7E8E98,
  custom-icons-color: #57636C,
  checkbox-basic-text-color: #57636C,
  checkbox-text-font-weight: normal,
  checkbox-text-font-size: 14px,
  user-picture-box-background-color: #E9EBED,
  header-height: 56px,
  user-medium-height: 2.3em,
  user-medium-width: 2.3em,
  link-text-decoration: none,
  layout-text-font-size: 14px,
  calendar-width: 300px,
  calendar-text-font-size: 14px,
  accordion-item-text-font-size: 14px,
  calendar-weekday-text-font-size: 14px,
  calendar-navigation-padding: 0.45rem 0.25rem,
  calendar-day-cell-height: 40px,
  tabset-tab-underline-width: 2px,
  tabset-tab-active-underline-color: #2D8C6D,
  tabset-tab-active-text-color: #2D8C6D,
  sidebar-menu-item-highlight-color: #2D8C6D,
  tabset-tab-hover-text-color: #343a40,
  tabset-tab-hover-underline-color: #343a40,
  tabset-tab-text-transform: none,
  tabset-content-padding: 1rem 0,
  tabset-tab-padding: 0.6rem 1.2rem,
  tabset-divider-width: 0,
  tabset-tab-text-font-weight: 500,
  sidebar-width: 13rem,
  card-border-radius: 8px,

  menu-item-icon-margin: 0 0.5rem 0 0,

  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,

  select-min-width: 6rem,

  slide-out-background: #f7f9fc,
  slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
  slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
), default, default);
