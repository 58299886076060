@import './themes';
@import 'shared';

@mixin nb-overrides() {
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
    }
  }
}
body.pace-running {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.035);
    z-index: 1040;
    min-height: 100vh;
  }
}
.font-weight-600 {
  font-weight: 600;
}
.font-size-14 {
  font-size: 14px;
}
.color-2 {
  color: $gray-soft;
}
.btn {
  white-space: nowrap;
  font-weight: 600;
  padding: 6px 16px;
  &:not([class*="btn-"]) {
    color: #57636C;
  }
  &.btn-dropdown-params-select {
    text-align: left;
    &.dropdown-toggle::after {
      position: absolute;
      right: 10px;
      top: 50%;
    }
  }
  &.btn-dropdown-params-select, &.btn-dropdown-params-select:hover, &.btn-dropdown-params-select:active {
    background-color: inherit !important;
    color: inherit !important;
    width: 250px !important;
  }
  &:focus, &.focus {
    box-shadow: none;
  }
  &:disabled {
    background-color: #F2F2F2 !important;
    color: #CCCCCC;
    border-color: #BDC3C7;
  }
  &:not(.btn-only-icon) nb-icon {
    margin-right: 5px;
  }
  nb-icon {
    font-size: 14px;
  }
}
.form-control[readonly] {
  background-color: #fff !important;
}
ng-select.ng-select-focused .ng-select-container,
.input-group > .form-control:focus ~ .input-group-append button,
.form-control:focus {
  box-shadow: 0px 0px 0px 2px #CBF0FA;
  border-color: #00AAD9;
}
.btn-icon {
  padding: 3px 2px;
  nb-icon {
    font-size: 15px;
  }
}
.btn-circle {
  width: 35px;
  height: 35px;
  border-radius: 50% !important;
  text-align: center;
  position: relative;
  nb-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.btn-outline-secondary {
  border-color: $gray-light;
  color: $gray-soft;
}
label,
.table thead th {
  font-weight: 600;
}
.form-group label,
.text-resalted,
.table thead th, .btn-icon {
  color: $gray-hard;
}
.text-muted {
  color: $gray-soft;
}
.text-small { font-size: 12px; }
.table:not(.table-sm) th, .table:not(.table-sm) td {
  border-top: 0 !important;
  border-bottom: 1px solid #E9EBED;
  padding: 10px 16px;
}
.table th, .table td {
  padding-left: 16px;
  padding-right: 16px;
}
.table thead th {
  border-bottom: 1px solid $gray-light;
}

.form-group label {
  line-height: 18px;
}
nb-sidebar { z-index: 1 !important;}
nb-menu .menu-item a.active {
  position: relative;
  &::before {
    left: 0;
    position: absolute;
    content: "";
    top: 0;
    height: 100%;
    width: 2px !important;
    background: var(--sidebar-menu-item-highlight-color);
  }
}

nb-menu .menu-item {
  border-bottom: 0 !important;

  .far::before {
    font-size: 15px;
  }
}
.badge {
  padding: 4px 8px;
  font-weight: 600;
  &.badge-active {
    background-color: #E6EEF6;
  }
  &.badge-inactive {
    background-color: #F4F5F5;
    color: $gray-soft;
  }
}

.page-title {
  margin-bottom: 30px;
  h1 {
    margin: 0;
    font-size: 36px;
  }
  h3 {
    font-size: 28px;
  }
}

nb-alert {
  text-align: center;
}

.fixed-buttons-layout {
  position: relative;
  padding-bottom: 100px !important;
  // -webkit-transform: translateZ(0);
  // transform: translateZ(0);

  .fixed-buttons {
    box-shadow: 0 0 15px rgba(24, 39, 75, 0.15);
    padding: var(--layout-padding);
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 90px;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    text-align: right;
    display: flex;
    justify-content: flex-end!important;
    background-color: white;

    .btn {
      align-self: center !important;
    }
  }
}

div:where(.swal2-container) h2:where(.swal2-title) {
  font-size: 16px !important;
  line-height: 30px !important;
}
.swal2-actions {
  margin-top: 30px !important;
  .btn {
    margin: 0 8px;
    font-weight: 600;

    &.swal2-confirm {
      min-width: 110px;
    }
  }
}
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-html-container {
  margin: 0 10px 0 !important;
}
.gray-box{
  padding: 15px;
  border-radius: 8px;
  background-color: #FAFAFA;
  position: relative;
}
.soft-shadow {
  box-shadow: 0 0 5px 0 #0000001A;
}
.custom-control-label span {
  position: relative;
  top: 2px;
}
.line-separator {
  border-top: 1px solid $gray-light;
  margin: 30px 0;
}
nb-card-footer {
  text-align: right;
}

[class*="modal-xl"] {max-width: 1000px;}
[class*="modal-sm"] nb-dialog-container {
  width: 300px;
}
[class*="modal-md"] nb-dialog-container {
  width: 500px;
}
[class*="modal-lg"] nb-dialog-container {
  width: 700px;
}
[class*="modal-xl"] nb-dialog-container {
  width: 1000px;
}
[class*="-borderless"] {
  nb-card-header {border-bottom: 0;}
  nb-card-footer {border-top: 0;}
}
[class*="input-icon-"] {position: relative;}
.input-icon-right {
  input {
    padding-right: 35px;
  }
  nb-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    color: inherit;
    font-size: 16px;
  }
}
nb-dialog-container {
  nb-card-header {
    display: flex;
    padding: 1rem 1.5rem;
    justify-content: space-between !important;
  }
}
.modal-header-icon {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #E6EEF6;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  nb-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
  }
}
.custom-control-input:checked:not(:disabled) ~ .custom-control-label::before {
  border-color: $primary !important;
  background-color: $primary !important;
}
.form-check-input {
  top: -1px;
}

nb-tabset {
  &.vertical-tab {
    display: flex;

      .tabset {
        display: block !important;
        padding: 20px 25px 0 0 !important;
        .tab {
          margin-bottom: 5px !important;
          a:before {
            position: absolute;
            content: "";
            height: 100%;
            width: 2px !important;
            top: 0;
          }
        }
        }
  }
  &:not(.vertical-tab) .tab {
    .tab-link {
      border-bottom: 1px solid $gray-light;
      &:before {
        bottom: -1px !important;
      }
    }
    &.active .tab-link {
      font-weight: 600;
      border-bottom: 0 !important;
    }
  }
}

nb-popover {
  max-width: 500px;
}
.input-group {
  > .form-control:focus {z-index: 0;}
  button {
    transition: border-color none;
    nb-icon {
      margin: 0 !important;
    }
  }
  &.input-group-borderless {
    & > input {
      border-right: 0;
    }
    nb-icon {opacity: .5;}
    .input-group-append button {
      border-left: 0;
      border-color: #ced4da;
      background-color: white;

      &:hover, &:focus {
        background: #F4F5FA;
        color: $gray-hard;
        box-shadow: none;
      }
    }
    &:hover nb-icon {opacity: 1;}
  }
}
nb-context-menu .menu-item a .menu-title {text-align: left;}
.input-group-append {
  .input-group-text {
    font-size: 14px;
    background-color: transparent;
    border-radius: 8px;
  }
}
.was-validated .form-control:invalid,
[class*="input-icon-"].is-invalid .form-control,
.form-control.is-invalid {
  color: #EB0000 !important;
  box-shadow: none !important;
  background-image: none;
}
.custom-control.is-invalid,
ng-select.is-invalid .ng-select-container,
[class*="input-icon-"].is-invalid .form-control,
.input-group.is-invalid button,
.input-group > form-control.is-invalid ~ .input-group-append button,
.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #EB0000 !important;
  box-shadow: none !important;
}
.invalid-feedback {
  margin-top: 0.15rem;
  font-size: 90%;
  color: #EB0000;
}
.input-group.is-invalid ~ .invalid-feedback,
.custom-control.is-invalid ~ .invalid-feedback,
ng-select.is-invalid ~ .invalid-feedback,
[class*="input-icon-"].is-invalid ~ .invalid-feedback {
  display: block;
}
.form-check-input {
  margin-top: 0.25rem;
}
.dropdown-menu-checked {
  .dropdown-item {
    padding: 0.5rem 1rem;
  }
  .form-check-input {
    left: 50%;
    top: 50%;
    margin-left: 0;
    margin-top: 0;
    transform: translate(-50%, -50%);
  }
}
.dropdown-toggle::after {
  vertical-align: middle;
}
.custom-dropdown-toggle.dropdown-toggle::after {
  display: none;
}
.btn-group {
  position: relative;
  .title {
    position: absolute;
    top: -9px;
    font-size: 11px;
    left: 10px;
    background-color: #fff;
    padding: 0 5px;
    color: #2D8C6D;
  }
}
.dropdown-item.disabled:not(.dropdown-title), .dropdown-item:disabled:not(.dropdown-title) {
  opacity: .5;
}
.dropdown-menu-small {
  .dropdown-item {
    font-size: 13px;
    padding: 0.2rem 1.5rem;
  }
  .dropdown-title {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.scrollable-dropdown {
  &::-webkit-scrollbar {
    width: 0.3125rem;
    height: 0.3125rem;
  }
  &::-webkit-scrollbar-corner {
    background: #f7f9fc;
  }
  &::-webkit-scrollbar-thumb {
    background: #e4e9f2;
    cursor: pointer;
    border-radius: 0.15625rem;
  }
  &::-webkit-scrollbar-track {
    background: #f7f9fc;
  }
}
[dir=ltr] nb-action {
  border-left: 0;
  position: relative;
}
nb-user {
  .user-name {
    font-weight: 600 !important;
    color: #2D8C6D !important;
  }
  .user-picture {
    height: 20px !important;
    width: 20px !important;
  }
}
.logo-container .logo {
  border: 0 !important;
  padding-left: 5px !important;
}
.overlay-backdrop,
div:where(.swal2-container).swal2-backdrop-show, div:where(.swal2-container).swal2-noanimation {
  background: #002A5180 !important;
}

div:where(.swal2-container) h2:where(.swal2-title) {
  color: #374955 !important;
}
div:where(.swal2-container) .swal2-html-container {
  color: #57636C !important;
  font-size: 12px !important;
}
div:where(.swal2-container) div:where(.swal2-popup) {
  border-radius: 8px !important;
  box-shadow: 0px 8px 50px -1px #18274B80;
  padding-bottom: 20px !important;
}
.swal2-modal {
  .swal2-icon {
    width: 53px !important;
    height: 53px !important;
  }
  .swal2-icon {
    margin-top: 25px !important;
  }
  .swal2-warning {
    border-color: transparent !important;
    background: url("data:image/svg+xml,%3Csvg width='25' height='21' viewBox='0 0 25 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.9799 18.0474C24.749 19.3597 23.7836 21 22.2475 21H2.25226C0.71322 21 -0.247696 19.3571 0.519887 18.0474L10.5176 0.98376C11.2871 -0.329109 13.2143 -0.32673 13.9824 0.98376L23.9799 18.0474ZM12.25 14.5195C11.1915 14.5195 10.3333 15.3642 10.3333 16.4062C10.3333 17.4483 11.1915 18.293 12.25 18.293C13.3085 18.293 14.1667 17.4483 14.1667 16.4062C14.1667 15.3642 13.3085 14.5195 12.25 14.5195ZM10.4303 7.73776L10.7394 13.3159C10.7538 13.5769 10.9731 13.7812 11.2386 13.7812H13.2614C13.5269 13.7812 13.7462 13.5769 13.7606 13.3159L14.0697 7.73776C14.0853 7.45582 13.8573 7.21875 13.5705 7.21875H10.9295C10.6427 7.21875 10.4147 7.45582 10.4303 7.73776Z' fill='%23FFC107'/%3E%3C/svg%3E"), #FFF9E7 !important;
    background-repeat: no-repeat no-repeat !important;
    background-position: center center !important;
    background-size: 24px !important;
    .swal2-icon-content {display: none !important;}
  }

  button.swal2-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.53125 6L0.15625 9.34375C-0.0312499 9.53125 -0.0312499 9.875 0.15625 10.0625L0.9375 10.8437C1.125 11.0312 1.46875 11.0312 1.65625 10.8437L5 7.46875L8.375 10.8437C8.5625 11.0312 8.90625 11.0312 9.09375 10.8437L9.875 10.0625C10.0625 9.875 10.0625 9.53125 9.875 9.34375L6.5 6L9.875 2.625C10.0625 2.4375 10.0625 2.09375 9.875 1.90625L9.09375 1.125C8.90625 0.9375 8.5625 0.9375 8.375 1.125L5 4.5L1.65625 1.125C1.46875 0.937499 1.125 0.937499 0.937501 1.125L0.156251 1.90625C-0.0312492 2.09375 -0.0312493 2.4375 0.156251 2.625L3.53125 6Z' fill='%23374955'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat no-repeat !important;
    background-position: center center !important;
    background-size: 14px !important;
    color: transparent !important;
  }
}

div:where(.swal2-container) div:where(.swal2-popup).swal2-icon-warning {
  max-width: 450px;
}
.text-primary {
  color: #2D8C6D !important;
}
.square-container {
  position: relative;
}
.square-container:before {
  content: "";
  padding-top: 100%;
  display: block;
}
.pace .pace-progress-inner {
  box-shadow: 0 0 10px var(--text-primary), 0 0 5px var(--text-primary);
}
div:where(.swal2-icon).swal2-info {
  border-color: #2D8C6D !important;
  color: #2D8C6D !important;
}
div:where(.swal2-container) h2:where(.swal2-title) {
  color: #2D8C6D !important;
}
.form-group {
  margin-bottom: 0.7rem !important;
}
